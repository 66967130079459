import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-91581a46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-text-center p-text-bold p-text-uppercase p-py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: true,
    visible: _ctx.modelValue,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    header: _ctx.$tm('terminal.printerOptions')[_ctx.option]
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t("payment.total")) + " = ", 1),
        _createVNode(_component_NumberFormatter, { value: _ctx.total }, null, 8, ["value"]),
        _createTextVNode(" " + _toDisplayString(_ctx.activeActivity.currencySymbol), 1)
      ]),
      _createVNode(_component_Button, {
        disabled: !_ctx.selected.length,
        icon: "pi pi-print",
        "icon-pos": "right",
        style: {"width":"100%"},
        label: _ctx.$t('validate'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValidate()))
      }, null, 8, ["disabled", "label"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        value: _ctx.ticket.movements,
        selection: _ctx.selected,
        "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        rows: _ctx.ticket.movements.length,
        class: "p-datatable-customers p-datatable-sm p-datatable-gridlines",
        responsiveLayout: "scroll",
        "data-key": "index"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            selectionMode: "multiple",
            headerStyle: "width: 3rem"
          }),
          _createVNode(_component_Column, {
            style: {"min-width":"50px"},
            header: _ctx.$t('product.article'),
            field: "name",
            sortable: true
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('product.quantity'),
            "body-class": "p-text-center"
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_NumberFormatter, {
                value: slotProps.data.quantity
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["header"]),
          ([2, 5].includes(_ctx.option))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                "body-class": "p-text-right",
                style: {"min-width":"50px"},
                header: _ctx.$t('pos.amount')
              }, {
                body: _withCtx(({ data }) => [
                  _createVNode(_component_NumberFormatter, {
                    value: data.price * data.quantity
                  }, null, 8, ["value"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.activeActivity.currencySymbol), 1)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["value", "selection", "rows"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}