
import { defineComponent, ref, computed } from "vue";
import { activityLogo, formatDate, totalInt } from "@/graphql/utils/utils";
import { TicketRow } from "@/graphql/ticket/create.ticket";
import { activeActivity } from "@/plugins/i18n";
import { printElement } from "@/graphql/ticket/ticket.printer";
export default defineComponent({
  name: "PrintSetupDialog",
  props: ["ticket", "modelValue", "option"],
  emits: ["update:modelValue", "update:ticket"],
  setup(props, { emit }) {
    const selected = ref<TicketRow[]>([...props.ticket.movements]);
    return {
      selected,
      total: computed(() => totalInt(selected.value)),
      activeActivity,
      activityLogo,
      formatDate,
      onValidate: () => {
        emit("update:modelValue", false);
        emit("update:ticket", { ...props, movements: selected.value });
      },
    };
  },
});
